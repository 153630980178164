import React, { useState, useRef, useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";

// Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

//Components
import { Container, Button, Input } from "./../Bootstrap";
import EventDetails from "./../EventDetails/EventDetails";

// Thirdparty
import Modal from "react-modal";
import { Row, Col, Form, Toast } from "react-bootstrap";
// import IdleTimer from "react-idle-timer";
import { useIdleTimer } from "react-idle-timer";

import { useForm } from "react-hook-form";
import "./sliderPage.css";
import SentimentSlider from "./../SentimentSlider/SentimentSlider";

const pollingIntervalTime = 10000;
var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}

Modal.setAppElement(document.getElementById("root"));

const SliderPage = (props) => {
  // States
  const [rating, setRating] = useState(50);
  const [redirectToQuestions, setRedirectToQuestions] = useState(false);
  const [continueButton, setContinueButton] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [eventAuthor, setEventAuthor] = useState("");
  const [commentsEnnabled, setCommentsEnabled] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [timeOut, setTimeOut] = useState(1000 * 120 * 1);
  const [path, setPath] = useState("");

  // Refs
  const idleTimer = useRef();

  // Form validator
  const { register, errors, handleSubmit } = useForm();

  //TODO: remove this function, after moving/handling redirect options redirect 
  //TODO: addComments now returned in data object
  // Handlers
  const finishSlider = (e) => {
    e.preventDefault();
    const newRequest = new Request(`${myServer}/api/isThereComments`, {
      method: "GET",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });

    fetch(newRequest).then((response) => {
      response.json().then((res) => {
        var questions = [];
        questions = localStorage.getItem("Questions");

        if (questions === "" && res.message === 1) {
          setRedirectToQuestions(true);
          setPath("/comments");
          setContinueButton(true);
        } else if (questions === "" && res.message === 0) {
          setRedirectToQuestions(true);
          setPath("/subscribe");
          setContinueButton(true);
        } else {
          setRedirectToQuestions(true);
          setPath("/questions");
          setContinueButton(true);
        }
      });
    });
  };

  const handleOnAction = (e) => {
    if (showModal) {
      setShowModal(!showModal);
    }
  };
  const handleOnActive = (e) => {
    if (showModal) {
      setShowModal(!showModal);
    }
  };

  const onChange = (ratingValue) => {
    setRating(ratingValue);
    slider(ratingValue);
  };

  const handleOnIdle = (e) => {
    setShowModal(true);
    // idleTimer.current.reset();
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: timeOut,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 250,
  });

  const slider = (ratingValue) => {
    const newRequest = new Request(`${myServer}/api/value`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ rating: ratingValue }),
    });
    fetch(newRequest)
      .then(function (response) {
        response.json().then((res) => {
          //TODO: handle reponse - we should be returning and evaluating some sort of status
          //currently, the application will not handle errors when submitting to closed events


        });
      })
      .catch((error) => {
        //TODO: verify that we don't need to catch this error

        return error;
      });
  };

  const comment = (data, e) => {
    var input = data.userComments;

    const commentRequest = new Request(`${myServer}/api/comment`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ comment: input }),
    });
    fetch(commentRequest).then(function (response) {
      response.json().then((res) => {
        setShowToast(true);
        document.getElementById("commentText").value = "";
      });
    });
  };

  const getEventStatus = (eventKey, e) => {
    const eventStatusRequest = new Request(`${myServer}/api/isEventOn`, {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ entranceKey: eventKey }),
    });

    fetch(eventStatusRequest).then((response) => {
      response.json().then((res) => {
        if (res.statusCode === 404) {
          var questions = [];
          questions = localStorage.getItem("Questions");
  
          setRedirectToQuestions(true);
          setContinueButton(true);
          if (res.data.showSurvey === 1) {
            setPath("/questions");
          } else if (res.data.addComments === 1) {
            setPath("/comments");
          } else {
            setPath("/subscribe");
          }
        } else if (res.statusCode === 403) {
          setPath(":" + eventKey + "/instructions");
        }
      });
    });    
  };

  // Component did mount
  useEffect(() => {
    let pathname = props.match.params.token;
    let entranceKey = pathname.split(":");

    const getEventInfo = new Request(myServer + "/api/getDescription", {
      method: "GET",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
        entranceKey: entranceKey[1],
      }),
    });

    fetch(getEventInfo).then((response) => {
      response.json().then((res) => {
        if (res.message === "success") {
          setEventAuthor(res.presenterName);
          setEventTitle(res.eventTitle);
          setCommentsEnabled(res.commentsEnabled);
          return true;
        }
      });
    });

    //check event status and set interval to keep checking
    getEventStatus(entranceKey[1]);
    setInterval(() => getEventStatus(entranceKey[1]), 10000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirectToQuestions) {
    return <Redirect to={"/" + props.match.params.token + path}></Redirect>;
  }

  return (
    <DefaultLayout>
      <Container>
        {showModal && (
          <div
            id="modal"
            className="shake-constant shake-constant--hover shake-slow"
          >
            <p>Are you still there?</p>
          </div>
        )}

        <EventDetails title={eventTitle} presenterName={eventAuthor} />

        <SentimentSlider changeHandler={(e) => onChange(e)} value={rating} />
        {commentsEnnabled ? (
          <Form onSubmit={handleSubmit(comment)}>

            <Form.Group>
              <Form.Control
                as="textarea"
                name="userComments"
                ref={register({ required: true })}
                id="commentText"
                className="sliderCommentText"
                placeholder="Comment"
                rows="4"
                required
              />
              {errors.userComments?.type === "required" && (
                <small className="text-danger">
                  Comments cannot be empty
                </small>
              )}
            </Form.Group>
            <Form.Group className="d-flex justify-content-end">
              <Button
                style={{
                  height: "2.7rem",
                  minHeight: "2.7rem",
                  lineHeight: "1"
                }}
                className="btn btn-md btn-primary"
                type="submit"
                id="comment"
                color="primary"
              >
                Send
              </Button>
            </Form.Group>

            <p xs={12} className="mb-3 text-center">
              <small>
                Your input is anonymous and will be available to your host after their presentation.
              </small>
            </p>

          </Form>
        ) : null}

        {showToast && (
          <div>
            <Toast
              className="presentii-toast"
              onClose={() => setShowToast(false)}
              show={showToast}
              delay={3000}
              autohide
            >
              <Toast.Header>
                <strong className="mr-auto">Presentii</strong>
              </Toast.Header>
              <Toast.Body>{"Comment submitted."}</Toast.Body>
            </Toast>
          </div>
        )}
      </Container>
    </DefaultLayout>
  );
};

export default withRouter(SliderPage);
