import React from "react";

// Components
import { Wrapper, Footer } from "./DefaultLayout.styles";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import MobileWaves from "./../../assets/img/login_waves_mobile.svg";

const DefaultLayout = ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      <NavigationBar />
      {children}
      <Footer>
        <img src={MobileWaves} alt="Presentii Footer" />
      </Footer>
    </Wrapper>
  );
};

export default DefaultLayout;
