import React from "react";
import { Slider, SliderCaption } from "./SentimentSlider.styles";

const SentimentSlider = (props) => {
  return (
    <React.Fragment>
      <Slider
        className="horizontal-slider"
        withTracks={false}
        onAfterChange={(val) => {
          props.changeHandler(val);
        }}
        {...props}
      />
      <SliderCaption>
        Slide the meter above left or right to automatically record your interest level.
      </SliderCaption>
    </React.Fragment>
  );
};

export default SentimentSlider;
