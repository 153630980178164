import React from "react";
import { EventTitle, EventAuthor } from "./EventDetails.styles";

const EventDetails = (props) => {
  return (
    <React.Fragment>
      <EventTitle>{props.title}</EventTitle>
      <EventAuthor>
        Presented by: <strong>{props.presenterName}</strong>
      </EventAuthor>
    </React.Fragment>
  );
};

export default EventDetails;
