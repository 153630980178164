import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import { Title, StatusMessage, TextAreaComments, Information } from "./Comments.styles";

// Components
import EventLogo from "../EventLogo/EventLogo";
import { Row, Col } from "react-bootstrap";
import { Container, Button, TextArea } from "./../Bootstrap";
import { H1 } from "./../Typography/Titles.styles";

var myServer = "";
if (process.env.NODE_ENV === "production") {
  myServer = process.env.REACT_APP_SERVER;
}
export default class Comments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
    };
    this.addComment = this.addComment.bind(this);
    this.changeKey = this.changeKey.bind(this);
  }
  addComment(e) {
    e.preventDefault();
    let self = this;
    var input = document.getElementById("additionalComment").value;
    const commentRequest = new Request(myServer + "/api/comment", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ comment: input }),
    });
    fetch(commentRequest).then(function (response) {
      response.json().then((res) => {
        self.setState({
          redirect: true,
        });
        localStorage.clear();
      });
    });
  }
  changeKey(e) {
    if ((e.key === 'Enter') && e.shiftKey) {
      this.addComment(e);
    }
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={"/" + this.props.match.params.token + "/subscribe"}
        ></Redirect>
      );
    }
    return (
      <DefaultLayout>
        <Container>
          <Row>
            <Col xs={12} lg={6} className="d-none d-lg-block">
              <EventLogo />
            </Col>
            <Col
              xs={12}
              lg={6}
              className="mb-5 my-lg-0 text-center d-lg-flex flex-column align-items-center justify-content-center "
            >
              <StatusMessage>Thank you for engaging with Presentii and for providing valuable input!</StatusMessage>

              <Title>Additional Comments</Title>
              <Information>Grab your last opportunity to share any final anonymous feedback with your presenter.</Information>
              <TextAreaComments
                id="additionalComment"
                className="textarea"
                rows={4}
                placeholder="Comment"
                onKeyUp={(e) => this.changeKey(e)}
              />
              <Row className="w-100 m-0">
                <Col xs={12} className="pt-3 px-0 d-flex justify-content-end">
                  <Button
                    className="btn btn-primary"
                    color="primary"
                    onClick={this.addComment}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </DefaultLayout>
    );
  }
}
