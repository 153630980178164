import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components/macro";

// Config
import theme from "./config/theme.js";

// Assets
import "./App.css";

// Views
import Login from "./views/Login/Login";
import Instruction from "./components/Instruction/Instruction";
import { BrowserRouter } from "react-router-dom";
import SliderPage from "./components/SliderPage/SliderPageHooks";
import QuestionsPage from "./components/Questions/QuestionsPage";
import Comments from "./components/AdditionalComments/Comments";
import Subscribe from "./components/Subscribe/Subscribe";

//google tag manager
import TagManager from "react-gtm-module";
if (process.env.REACT_APP_GTM && process.env.REACT_APP_GTM != '') {
  let tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM
  }
  TagManager.initialize(tagManagerArgs);
}

//created private Route
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAttendee() ? <Component {...props} /> : <Redirect to="/" />
    }
  />
);

//check if a user is authenticated if not then redirect
const checkAttendee = () => {
  if (document.cookie && document.cookie.indexOf("session") !== -1) {
    return true;
  } else {
    return false;
  }
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <BrowserRouter forceRefresh={true} basename={"/"}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <Switch>
              <Route exact path={"/"} component={Login} ID=""></Route>
              <Route exact path={"/subscribe"} component={Subscribe}></Route>
              <Route exact path={"/:token"} component={Instruction}></Route>
              <PrivateRoute
                exact
                path={"/:token/slider"}
                component={SliderPage}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path={"/:token/questions"}
                component={QuestionsPage}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path={"/:token/comments"}
                component={Comments}
              ></PrivateRoute>
              <PrivateRoute
                exact
                path={"/:token/subscribe"}
                component={Subscribe}
              ></PrivateRoute>
              <Route path="*" component={() => <div>NoMatch</div>} />
            </Switch>
          </div>
        </ThemeProvider>
      </BrowserRouter>
    );
  }
}
export default App;
