import styled from "styled-components/macro";

export const H1 = styled.h1`
  color: ${({ theme }) => theme.colors.alt.base};
  font-size: 2rem;
  margin-bottom: 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 1.5rem;
  }
`;
