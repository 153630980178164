import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// Layout
import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";

// Components
import { Row, Col } from "react-bootstrap";
import { Container, Button } from "./../Bootstrap";

import * as Survey from "survey-react";
import EventLogo from "../EventLogo/EventLogo";
import "survey-react/survey.css";
import "./QuestionsPage.css";

export default class QuestionsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventTitle: "",
      currentQuestion: [
        {
          id: "",
          text: "",
          type: "",
          options: [
            {
              text: {
                text: "",
                id: "",
              },
            },
          ],
        },
      ],
      Questions: [],
      numOfQuestions: 0,
      answers: [],
      audioOption: "",
      previousOption: "",
      checkboxOption: "",
      checkedItems: new Map(),
      redirectToComments: false,
      redirectToHome: false,
      redirectToInstructions: false,
      redirectToSlider: false,
      redirectToSubscribe: false,
      json: "",
    };
    this.onChange = this.onChange.bind(this);
    this.onComplete = this.onComplete.bind(this);
  }
  UNSAFE_componentWillMount() {
    var Questions = localStorage.getItem("Questions");
    if (Questions.length === 0) {
      this.setState({
        redirectToSlider: true,
      });
    } else {
      var parsedQuestions = JSON.parse(Questions);
      Survey.StylesManager.applyTheme("bootstrap");
      var surveyJSON2 = {
        pages: parsedQuestions,
      };
      this.setState({
        json: surveyJSON2,
      });
    }
  }
  componentDidMount(e) {
    let pathname = this.props.match.params.token;

    let entranceKey = pathname.split(":");

    const newRequest = new Request("/api/isEventOn", {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ entranceKey: entranceKey[1] }),
    });

    fetch(newRequest).then((response) => {
      response.json().then((res) => {
        if (res.statusCode === 200) {
          this.setState({
            redirectToInstructions: true,
          });
        } else if (res.statusCode === 404) {
          this.setState({
            eventTitle: res.data.title
          });
        }
      });
    });
  }
  onComplete(survey, options) {
    //Write survey results into database

    let self = this;

    const newRequest = new Request("/api/answers", {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ answers: survey.data }),
    });
    const findIfComments = new Request("/api/isThereComments", {
      method: "GET",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });
    fetch(newRequest)
      .then(function (response) {
        response.json().then((res) => {
          if (res.data === "submitted") {
            fetch(findIfComments).then((response) => {
              response.json().then((res) => {
                if (res.message === 1) {
                  self.setState({
                    redirectToComments: true,
                  });
                } else {
                  self.setState({
                    redirectToSubscribe: true,
                  });
                }
              });
            });
          }
        });
      })
      .catch((error) => {
        return error;
      });
  }

  onChange(e) {
    if (e.target.name === "Multiple Choice") {
      this.setState({
        audioOption: e.target.value,
      });
    } else if (e.target.name === "Multi Answers") {
      const option = e.target.value;
      const { currentQuestion } = this.state;

      currentQuestion.options[option].checked = !currentQuestion.options[option]
        .checked;

      this.setState({
        currentQuestion,
      });
    }
  }
  render() {
    if (this.state.redirectToInstructions) {
      return <Redirect to={"/" + this.props.match.params.token}></Redirect>;
    } else if (this.state.redirectToComments) {
      return (
        <Redirect
          to={"/" + this.props.match.params.token + "/comments"}
        ></Redirect>
      );
    } else if (this.state.redirectToSlider) {
      return (
        <Redirect
          to={"/" + this.props.match.params.token + "/slider"}
        ></Redirect>
      );
    } else if (this.state.redirectToSubscribe) {
      return (
        <Redirect
          to={"/" + this.props.match.params.token + "/subscribe"}
        ></Redirect>
      );
    }

    var model;
    if (this.state.json != '' && this.state.json != undefined) {
      model = new Survey.Model(this.state.json);
    }

    return (
      <DefaultLayout>
        <Container>
          <h1>{ this.state.eventTitle }</h1>
          <EventLogo />
          <h2>
            Survey Questions
          </h2>
          <Survey.Survey
            style={{ width: "50%" }}
            model={model}
            onComplete={this.onComplete}
          />
          <div
            id="buttons"
            className="container d-flex justify-content-center"
            style={{ marginTop: "50px" }}
          ></div>
        </Container>
      </DefaultLayout>
    );
  }
}
