import styled, { css } from "styled-components/macro";
import { Form } from "react-bootstrap";

export const baseStyle = css`
  width: 100%;
  padding: 0.75rem;
  border-radius: ${({ theme }) => theme.borderRadius.input};
  border-width: 1px;
  border-color: ${({ theme }) => theme.forms.borderColor};
  background-clip: border-box;
  color: ${({ theme }) => theme.forms.textColor};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;
  font-size: 0.9186rem;
  letter-spacing: 1px;

  ${({ variant, theme }) =>
    variant === "alt" && `background-color: ${theme.colors.grey.base};`}

  &:focus {
    ${({ variant, theme }) =>
      variant === "alt" && `background-color: ${theme.colors.grey.base};`}

    color: ${({ theme }) => theme.forms.textColor};
    border-color: ${({ theme }) => theme.forms.focusBorderColor};
    box-shadow: ${({ theme }) => theme.forms.boxShadow};
  }

  ::-webkit-autofill,
  ::-webkit-autofill:hover,
  ::-webkit-autofill:focus {
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  ::placeholder {
    color: ${({ theme }) => theme.forms.placeholderColor};
  }

  &.is-invalid {
    background-image: none;
    padding-right: 0.75rem;

    &:focus {
    }
  }

  &:disabled,
  &[readonly] {
    background-color: #f2f2f2;
    border-color: #d6d6d6;
    color: grey;

    &:focus {
      box-shadow: none;
    }
  }
`;

export default styled(Form.Control)`
  ${baseStyle}
`;
