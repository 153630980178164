import styled, { createGlobalStyle } from "styled-components/macro";
import breakpoint from "styled-components-breakpoint";

export const BodyStyles = createGlobalStyle`
  @media only screen and (max-width: 768px) {
    body {
      background: ${({ theme }) => theme.colors.grey.lighter};
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint("md")`
    flex-direction: row;
  `}
`;

export const LeftColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 45%;
  justify-content: center;
  padding: 0;
  background-color: white;

  ${breakpoint("md")`
    min-height: 100vh;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 16.7rem 0 2rem;
    background-color: transparent;
  `}

  @media only screen and (min-width: 768px) and (max-width: 1180px) and (max-height: 950px) {
    padding: 0 13rem 0 2rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 950px) and (max-height: 950px) {
    padding: 0 11rem 0 2rem;
  }

  @media only screen and (min-width: 1180px) {
    flex: 0 0 45%;
  }

  @media only screen and (min-width: 1500px) {
    flex: 0 0 40%;
  }

  ${breakpoint("xl")`
    padding-right: 17rem;
  `}

  ${breakpoint("xxl")`
    padding-right: 20rem;
  `};
`;

export const RightColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 55%;
  background-color: ${({ theme }) => theme.colors.grey.lighter};

  ${breakpoint("md")`
    min-height: 100vh;
  `}

  @media only screen and (min-width: 1180px) {
    flex: 0 0 55%;
  }

  @media only screen and (min-width: 1500px) {
    flex: 0 0 60%;
  }
`;

export const BackgroundContainer = styled.div`
  position: relative;
  text-align: right;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    svg {
      display: block;
    }
  }

  ${breakpoint("md")`
    position: absolute;
    z-index: -1;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      width: 9rem;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.grey.lighter};
      z-index: -2;
    }

    svg {
      margin: 0;
      height: 100%;
    }
  `}
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 4.5rem 4.2rem 2.5rem;

  ${breakpoint("md")`
    padding: 21vh 0 0;
    justify-content: flex-start;
  `}

  svg {
    max-width: 374px;
    max-height: 175px;
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.alt.base};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.1rem;

  ${breakpoint("lg")`
    font-size: 1.8rem;
  `}
`;

export const Description = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.montserrat.medium};
  color: ${({ theme }) => theme.colors.grey.heavy};
  margin-bottom: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    text-align: center;
  }
`;

export const FormContainer = styled.div`
  max-width: 31.7857rem;
  width: 100%;
  padding-bottom: 2rem;
`;

export const FormGroupContainer = styled.div`
  padding-top: 2rem;
`;

export const LoginButtonContainer = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    text-align: center;
  }
  .btn {
    min-width: 8rem;
    padding-left: 3.05rem;
    padding-right: 3.05rem;
    font-weight: ${({ theme }) => theme.fontWeights.montserrat.regular};
  }
`;

export const MainContent = styled.div`
  padding: 3rem 2.5rem 0;

  ${breakpoint("md")`
    padding: 10vh 2.5rem 0;
  `}

  ${breakpoint("xl")`
    padding: 12vh 3rem 0;
  `}

  ${breakpoint("xl")`
    padding: 17vh 3rem 0;
  `}

  display: flex;
  justify-content: center;
`;
