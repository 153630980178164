import styled from "styled-components/macro";

export const Wrapper = styled.div`
  padding-bottom: 3.0714rem; /*Footer height*/
  font-family: "Montserrat", sans-serif;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    background-color: ${({ theme }) => theme.colors.grey.lighter};
    padding-bottom: 0;
  }
`;

export const Footer = styled.div`
  display: none;

  img {
    transform: scale(-1, -1);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: block;
  }
`;
