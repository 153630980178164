import React from "react";

// Assets
import { ReactComponent as Logo } from "./../../assets/img/logo_navigation.svg";
import { ReactComponent as LogoWaves } from "./../../assets/img/navigation_wave.svg";

// Components
import { Container } from "../Bootstrap";
import { ThemedNavbar, NavHomeLink, ThemedNavItem } from "./Navigation.styles";

const NavigationBar = () => {
  return (
    <ThemedNavbar collapseOnSelect variant="" expand="lg">
      <Container className="navbarContainer">
        <NavHomeLink href="/Home" className="mr-0">
          <Logo />
          <LogoWaves className="waves" />
        </NavHomeLink>
      </Container>
    </ThemedNavbar>
  );
};

export default NavigationBar;
