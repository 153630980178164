import styled from "styled-components/macro";

export const Logo = styled.div`
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
    border: 5px solid ${({ theme }) => theme.colors.grey.dark};
  }
`;
