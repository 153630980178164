import styled from "styled-components/macro";
import { Navbar, NavItem } from "react-bootstrap";
import breakpoint from "styled-components-breakpoint";

export const ThemedNavbar = styled(Navbar)`
  background-color: ${({ theme }) => theme.colors.grey.dark};
  padding: 0;
  align-items: start;
  margin-bottom: 4rem;

  ${breakpoint("lg")`
	max-height: 5.6429rem;
  margin-bottom: 14rem;
    `}

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    background-color: transparent;
  }
  .container {
    align-items: start;
  }

  .navbar-toggler {
    padding: 0;
    position: relative;
    top: 51px;
    z-index: 3;

    &:not(.collapsed) {
      .hamburger-icon__path1 {
        transform: rotate(45deg) translate(1px, -4px);
      }
      .hamburger-icon__path2 {
        transform: translateX(-100%);
      }
      .hamburger-icon__path3 {
        transform: rotate(-45deg) translate(2px, 2px);
      }
    }

    .hamburger-icon {
      &__path1 {
        transform-origin: left;
        transform-box: fill-box;
        transition: transform 250ms ease-out;
      }
      &__path2 {
        transition: transform 250ms ease-out;
      }
      &__path3 {
        transform-origin: left;
        transform-box: fill-box;
        transition: transform 250ms ease-out;
      }
    }
  }

  .navbar-collapse {
    @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
      padding: 200px 0 40vmin;
      position: fixed;
      overflow-y: auto;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100vh;
      background: rgb(235, 235, 235);
      z-index: 1;
    }
  }

  .navbar-waves {
    transform: scale(-1, -1);
    position: fixed;
    bottom: 62px;

    .bottom-wave {
      fill: #ebebeb;
    }
  }

  .collapsing {
    -webkit-transition: none;
    transition: none;
    display: none;
  }

  .navbar-nav {
    @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
      background-color: transparent;
    }
  }
`;

export const NavHomeLink = styled(Navbar.Brand)`
  background-color: ${({ theme }) => theme.colors.grey.light};
  position: relative;
  padding: 0rem 1.1rem;
  z-index: 3;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    background-color: white;
  }

  ${breakpoint("lg")`
  padding: 4.0714rem 2.6429rem 1.4286rem;
    `}

  svg {
    width: 8.5rem;
    height: 5.5rem;
    max-width: 100%;

    ${breakpoint("xl")`
      width: 19.2857rem;
      height: 7.9286rem;
    `}
  }

  .waves {
    width: 100%;
    max-height: 4rem;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);

    @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
      path {
        fill: white;
      }
    }
  }
`;
