import React from "react";
import styled from "styled-components/macro";
import ReactSlider from "react-slider";
import PropTypes from "prop-types";

const StyledSlider = styled(ReactSlider)`
  position: relative;
  width: 100%;
  height: 15px;
  margin: 3rem 0 1rem 0;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.alt.base} 0%,
    ${({ theme }) => theme.colors.primary.base} 100%
  );

  &:before {
    content: "-";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 30px;
    font-weight: bold;
    transform: translateY(-100%);
    color: ${({ theme }) => theme.colors.alt.base};
    pointer-events: none;
  }

  &:after {
    content: "+";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    font-weight: bold;
    transform: translateY(-100%);
    color: ${({ theme }) => theme.colors.primary.base};
    pointer-events: none;
  }
`;

const StyledThumb = styled.div`
  height: 35px;
  line-height: 30px;
  width: 35px;
  text-align: center;
  background-color: white;
  border: 2px solid #d6d6d6;
  color: #fff;
  border-radius: 50%;
  margin-top: -10px;
  margin-left: -2px;
  outline: none;
  cursor: grab;
`;

const Thumb = (props) => <StyledThumb {...props}></StyledThumb>;

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${({ index, colors }) =>
    index === 2 ? colors[2] : index === 1 ? colors[1] : colors[0]};
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.primary.base} 0%,
    ${({ theme }) => theme.colors.alt.base} 100%
  );
`;

const Track = (props, state, colors) => {
  return <StyledTrack {...props} colors={colors} index={state.index} />;
};

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Slider = ({ className, colors, ...props }) => {
  return (
    <SliderContainer className={className}>
      <StyledSlider
        renderTrack={(props, state) => Track(props, state, colors)}
        renderThumb={Thumb}
        {...props}
      />
    </SliderContainer>
  );
};

Slider.defaultProps = {
  colors: ["#F0DE00", "#FA7C02", "#E30A25"],
};

const CaptionDiv = styled.div`
  text-align: center;
  margin-bottom: 2em;
`;

const SliderCaption = ({ children }) => (
  <CaptionDiv>
    <small>{children}</small>
  </CaptionDiv>
);

SliderCaption.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Slider, SliderCaption };
