import React, { Component } from "react";
import { Logo } from "./EventLogo.styles";

const admin = process.env.REACT_APP_ADMIN;

export default class EventLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageURL: "",
    };
  }

  componentDidMount() {
    let self = this;
    const newRequest = new Request("/api/getLogo", {
      method: "GET",
      credentials: "include",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    });
    fetch(newRequest).then((response) => {
      response.json().then((res) => {
        const currentImage = res.data[0].Logo;
        if (!currentImage) {
          return;
        }
        if (currentImage === "image_placeholder.png") {
          return;
        }
        let path;
        const {
          data,
          eventID,
        } = res;
        if (res.usingCompany === "true") {
          path = `Users/${data[0].companyID}`;
        } else {
          path = `Events/${eventID}`;
        }
        const imageURL = `${admin}/Uploads/${path}/images/${currentImage}`;
        self.setState({ imageURL });
      });
    });
  }
  render() {
    const { imageURL } = this.state;
    return (
      <Logo>
        {imageURL && <img alt="Company Logo" src={imageURL}/>}
      </Logo>
    );
  }
}
