import styled from "styled-components/macro";

export const Wrapper = styled.a`
  position: relative;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.primary.base};

  &:hover {
    color: ${({ theme }) => theme.colors.primary.base};
    text-decoration: none;
  }

  &:before {
    position: absolute;
    content: "";
    bottom: -0.2rem;
    display: block;
    width: calc(100% - 0.5rem);
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary.base};
  }
`;
