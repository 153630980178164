import styled from "styled-components/macro";

export const EventTitle = styled.h1`
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.alt.base};
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 0.1em;
`;
export const EventAuthor = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary.base};
  margin-bottom: 0.2rem;
`;
