import styled, { createGlobalStyle } from "styled-components/macro";
import breakpoint from "styled-components-breakpoint";

export const StatusMessage = styled.p`
  font-weight: 900;
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.primary.base};
  text-align: center;
  margin-bottom: 2.5rem;
  line-height: 1.1;
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.alt.base};
  text-transform: uppercase;
  text-align: center;
  line-height: 1.1;

  ${breakpoint("lg")`
    font-size: 1.4rem;
  `}
`;

export const Information = styled.p`
  font-size: 0.85rem;
`;

export const TextAreaComments = styled.textarea`
  background-color: #edeeee;
  color: #333;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border: solid 1px #ddd;
  width: 100%;

  ${breakpoint("lg")`
    width: inherit;
  `}
`;
